import dayjs from 'dayjs';
import Link from 'next/link';
import { memo } from 'react';
import Brands from '~/components/Services/Brands';

const Loading = memo(() => {
  return (
    <>
      <main className="flex min-h-dvh flex-col items-center justify-center">
        <Brands className="m-auto animate-pulse !text-3xl" />
        <div className="fixed inset-x-0 bottom-4 mx-auto flex flex-col items-center justify-center">
          <h1 className="mt-5 whitespace-nowrap text-[13px] opacity-60">
            &copy; {dayjs().format('YYYY')} MeeFund by{' '}
            <strong>
              <Link scroll={false} href={'https://instagram.com/zaadevofc'} target="_blank">
                zaadevofc
              </Link>
            </strong>
          </h1>
        </div>
      </main>
    </>
  );
});

export default Loading;
